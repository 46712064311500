import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Inspection',
    name: 'Inspection',
    component: () => import('../views/Inspection.vue')
  },
  {
    path: '/Consultation',
    name: 'Consultation',
    component: () => import('../views/Consultation.vue')
  },
  {
    path: '/Partners',
    name: 'Partners',
    component: () => import('../views/Partners.vue')
  },
  {
    path: '/Projects',
    name: 'Projects',
    component: () => import('../views/Projects.vue')
  },
  {
    path: '/Projects/detail',
    name: 'detail',
    component: () => import('../views/Detail.vue')
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
