<template>
  <div class="leftHead">
    <router-link class="logo" to="/">
      <img src="../assets/images/logo.png" />
    </router-link>
    <img class="menu" @click="isShow = true" src="../assets/images/menu.png">
    <div class="leftMenu" :class="{'leftMenuHover': isShow}">
      <router-link to="/Inspection">Inspection</router-link>
      <router-link to="/Consultation">Consultation</router-link>
      <router-link to="/Partners">Partners</router-link>
      <router-link to="/Projects">Projects</router-link>
      <router-link to="/Contact">Contact</router-link>
      <span @click="isShow = false"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navHead',
  data() {
    return {
      isShow: false
    }
  }
}
</script>

<style scoped>
</style>
