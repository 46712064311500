<template>
  <div class="box">
    <div class="left">
      <img class="leftBg" src="../assets/images/indexBg.jpg">
      <div class="leftImg">
        <navHead />
        <div class="leftTxt">
          Global Perspective<br />Local Precision
        </div>
        <div class="leftDown">
          <div>© Lionox, 2021</div>
          <div class="share">
            <a href="javascript:window.open('http://twitter.com/home?status='+encodeURIComponent(document.location.href)+' '+encodeURIComponent(document.title),'_blank','toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350');void(0)"><img src="../assets/images/img_25.png" /></a>
            <a href="javascript:window.open('http://www.facebook.com/sharer.php?u='+encodeURIComponent(document.location.href)+'&t='+encodeURIComponent(document.title),'_blank','toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350');void(0)"><img src="../assets/images/img_27.png" /></a>
            <a href="javascript:window.open('http://www.linkedin.com/shareArticle?mini=true&url='+encodeURIComponent(document.location.href)+'&title='+encodeURIComponent(document.title)+'&source='+encodeURIComponent(document.location.href),'_blank','toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350');void(0)"><img src="../assets/images/img_29.png" /></a>
            <!-- <a href="#"><img src="../assets/images/img_31.png" /></a> -->
            <!-- <a href="#"><img src="../assets/images/img_33.png" /></a> -->
          </div>
        </div>
      </div>
    </div>
    <div class="right" :class="'right right' + step">
      <div class="rightOver">
        <div class="rightBanner" :style="{transform: 'rotate('+deg+'deg)'}">
          <img src="../assets/images/index_06.png" />
          <img src="../assets/images/img5.png" />
          <img src="../assets/images/img3.png" />
          <img src="../assets/images/img4.png" />
        </div>
      </div>
      <div class="rightText">
        <div class="bannerLeft" @click="goStep()"><img src="../assets/images/index_09.png" /></div>
        <div class="bannerCenter" v-if="step === 1" @click="goDetail('/Projects/detail?id=10')">
          <p>Crystalliser Vessel</p>
          <p>Installation & Commissioning</p>
          <a href="#">View Project Details</a>
        </div>
        <div class="bannerCenter" v-if="step === 2" @click="goDetail('/Projects/detail?id=5')">
          <p>Wind Power</p>
          <p>Facilities Fabrication</p>
          <a href="#">View Project Details</a>
        </div>
        <div class="bannerCenter" v-if="step === 3" @click="goDetail('/Projects/detail?id=2')">
          <p>Qatar Offshore</p>
          <p>Oil Field Development</p>
          <a href="#">View Project Details</a>
        </div>
        <div class="bannerCenter" v-if="step === 4" @click="goDetail('/Projects/detail?id=4')">
          <p>Shell Penguins FPSO</p>
          <a href="#">View Project Details</a>
        </div>
        <div class="bannerRight" @click="goStep(true)"><img src="../assets/images/index_10.png" /></div>
      </div>
    </div>
  </div>
</template>

<script>

import navHead from '/src/components/navHead'

export default {
  name: 'Home',
  components: {
    navHead
  },
  data(){
    return {
      step: 1,
      deg: 0
    }
  },
  methods:{
    goStep(type = false){
      this.deg = this.deg + (!type ? 90 : -90)
      if (type) {
        this.step = this.step === 4 ? 1 : this.step + 1
      } else {
        this.step = this.step === 1 ? 4 : this.step - 1
      }
    },
    goDetail(url) {
      this.$router.push(url)
    }
  }
}
</script>

<style scoped>
.box{
  max-width: 1900px;
}
.rightBanner{
  left:50%;
  margin-left: -890px;
}
.leftBg{
  height: 100%;
  width: auto;
  left: auto;
  right: 0;
}
</style>
